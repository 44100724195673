import i18n from '@/i18n';
import { BvTableField } from 'bootstrap-vue';

const columns: Array<{ key: string } & BvTableField> = [
  {
    label: i18n.tc('colunas.nome.consultor', 1) as string,
    key: 'usuarios.id',
    sortable: true,
    thStyle: { width: '150px' },
  },
  {
    label: i18n.tc('colunas.nome.fatura', 2) as string,
    key: 'nome',
    sortable: false,
  },
  {
    label: i18n.tc('colunas.placa', 2) as string,
    key: 'placa',
    sortable: false,
    thStyle: { width: '80px' },
  },
  {
    label: i18n.tc('colunas.tipo', 2) as string,
    key: 'tipo',
    sortable: false,
    thStyle: { width: '100px' },
  },
  {
    label: i18n.tc('colunas.valor.fatura', 2) as string,
    key: 'valor',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.t('colunas.situacao') as string,
    key: 'situacao',
    sortable: true,
    thStyle: { width: '80px' },
  },
  {
    label: i18n.tc('colunas.data.criacao', 2) as string,
    key: 'data_criacao',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.vencimento', 2) as string,
    key: 'data_vencimento',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.tc('colunas.data.pagamento', 2) as string,
    key: 'data_pagamento',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.t('faturas.colunas.tipos.veiculo') as string,
    key: 'tipo_veiculo',
    sortable: true,
    thStyle: { width: '100px' },
    thClass: 'text-center',
  },
  {
    label: i18n.t('faturas.colunas.tipos.adesao') as string,
    key: 'tipo_adesao',
    sortable: true,
    thClass: 'text-center',
  },
];

const situacoes: ISelectSituacao[] = [
  {
    text: i18n.t('faturas.situacoes.todos') as string,
    value: '',
    variant: '',
  },
  {
    text: i18n.t('faturas.situacoes.pendente') as string,
    value: 'pending',
    variant: 'secondary',
  },
  {
    text: i18n.t('faturas.situacoes.pago') as string,
    value: 'paid',
    variant: 'success',
  },
  {
    text: i18n.t('faturas.situacoes.cancelado') as string,
    value: 'canceled',
    variant: 'warning',
  },
  {
    text: i18n.t('faturas.situacoes.vencido') as string,
    value: 'expired',
    variant: 'danger',
  },
];

const columnsFilter: IFiltroField[] = [
  {
    label: i18n.tc('colunas.nome.consultor', 1) as string,
    id: 'usuarios.nome',
    type: 'text',
  },
  {
    label: i18n.tc('colunas.nome.fatura', 2) as string,
    id: 'faturas.nome',
    type: 'text',
  },
  {
    label: i18n.tc('colunas.placa', 2) as string,
    id: 'faturas.placa',
    type: 'text',
  },
  {
    label: i18n.tc('colunas.valor.fatura', 2) as string,
    id: 'faturas.valor',
    type: 'number',
  },
  {
    label: i18n.t('colunas.situacao') as string,
    id: 'faturas.situacao',
    type: 'enum',
    enum: situacoes.map<IFiltroEnum>((item) => {
      return { value: item.value, label: item.text };
    }),
  },
  {
    label: i18n.tc('colunas.data.criacao', 2) as string,
    id: 'faturas.data_criacao',
    type: 'date',
  },
  {
    label: i18n.tc('colunas.data.vencimento', 2) as string,
    id: 'faturas.data_vencimento',
    type: 'date',
  },
  {
    label: i18n.tc('colunas.data.pagamento', 2) as string,
    id: 'faturas.data_pagamento',
    type: 'date',
  },
];

export { columns, situacoes, columnsFilter };
